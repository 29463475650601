body {
  width:100%;
  height: 100%;
  background: black;
  color: #DDBEBC;
}

* {
  margin: 0;
  padding: 0;
}

.selected {
  background-color: #660b0b !important;
  /*color: #CC2222;*/
  font-weight: bold;
}
.item {
  display: inline;
  margin: 0 0 2em 0;
  list-style-type: none;
  padding: 0;
  width: 6em;
  left: 10px;
  top: -4px;
  height: 1.8em;
  margin-right: .8em;
  border-radius: 4px 0 0 4px;
}

li {
  cursor: pointer;
  position: relative;
  left: 0;
  background-color: #2C0B0B;
  margin: .5em;
  padding: .3em 0;
  height: 1.6em;
  border-radius: 4px;
  display: inline;
}
li.selected:hover {
  background-color: #720C0C !important;
  /*color: #CC2222;*/
  font-weight: bold;
}
li:hover {
  /*color: #607D8B;*/
  /*color: #DD3333;*/
  background-color: #350C0C;
  left: .1em;
}

.item_prop ul {
  display: block;
  -webkit-padding-start: 10px;
}
.item ul {
  display: inline;
}

.item_prop1 li {
  display: block;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 0px;
}

.item_prop2 li {
  display: block;
  text-align: center;
  padding-top: 12px;
  /*width:160px;*/
}
.item_prop3 li {
  display: block;
  text-align: center;
  height: 38px;
  padding-left: 4px;
  padding-right: 4px;
}

.item li {
  display: inline;
}
.item span {
  padding-left: 5px;
  padding-right: 5px;
}

.item_prop2 span {
  /*width:160px;*/
}

td {
  vertical-align: top;
}

/*.item {
  display: inline-block;
  font-size: small;
  color: white;
  padding: 0.8em 0.7em 0 0.7em;
  background-color: #607D8B;
  line-height: 1em;
  position: relative;
  left: -1px;
  top: -4px;
  height: 1.8em;
  margin-right: .8em;
  border-radius: 4px 0 0 4px;
}*/

.textarea, .button {
  font-family: Verdana, Arial, sans-serif;
  font-size: 10px;
  margin: 0px;
  width:100%;
  height: 100%;
  /*background: #653232; /*#6D0203; #3D5074;*/
  background: black url('img/input.jpg');
  border: 1px dotted #800d00;
  color: #DDBEBC;
}

.actionbutton{
  height: 30px;
}

.button:hover, .textarea:focus {
  background: black url('img/input_.jpg');
}

.cell_body {
  background: black url('img/input_.jpg');
  /*height: 90%;*/
}

.show {
  display: block;
}

.hide {
  display: none;
}

.good_connections_group {
  border: 1px dotted #800d00;
}

.boxes-in-line {
  display: inline-block;
  margin-left: 0px;
  vertical-align: top;
}

.bottom-table {
  width: 550px;
  margin: 8px;
}

.ratio:checked {
  color: "#CC2222";
}

.one-line {
  vertical-align: center;
}

.simulation-table {
  display: inline-block;
  margin-left: 0px;
  width: 100%;
  border: 1px;
  border-collapse: collapse;
}

.simulation-table tbody {
  display: table;
}

.simulation-tab {
  width: 292px;
}

.simulation-table td {
  vertical-align: middle;
  text-align: right;
}

.simulation-table .top-row {
  vertical-align: middle;
  text-align: left;
}

.result-groups {
  padding: 3px;
  border: 1px dotted #800d00;
  margin: 10px;
}

.iteration-counter {
  width: 48px;
}

.container {
  display: grid;
  grid-template-columns: auto 200px 200px 200px 10px 400px auto;
  grid-template-rows: 10px 200px 60px 60px auto auto auto 10px auto ;
}

.fill-left {
  grid-column: 1 / 2;
  grid-row: 1 / -1;
}

.fill-right {
  grid-column: -2 / -1;
  grid-row: 1 / -1;
}

.fill-top {
  grid-column: 1 / -1;
  grid-row: 1 / 2;
}

.input-items {
  grid-row: 2 / 3;
  grid-column: 2 / span 3;
}

.loaded-items {
  grid-row: 2 / 3;
  grid-column: -3 / -2;
}


.item-type {
  grid-row: 3 / 4;
  grid-column: 2 / span 3;
  align-self: center;
}

.item-quality {
  grid-row: 4 / 5;
  grid-column: 2 / span 3;
  align-self: center;
}

.selection_tab {
  background: black url('img/input.jpg');
  border-radius: 10px 10px 0 0;
  text-align: center;
  justify-self: stretch;
  grid-row: 5 / 6;
}

.selection_tab:hover {
  background: black url('img/input_.jpg');
  border-radius: 10px 10px 0 0;
}

.selection_tab.selected {
  background: black url('img/input_.jpg');
  border-radius: 10px 10px 0 0;
}

.info {
  grid-column: 2 / span 3;
  grid-row: 6 / 7;
}

.info .cell_body {
  padding: 10px;
}

.tab-content {
  grid-column: 2 / span 3;
  grid-row: 7 / 9;
}

.donate {
  grid-column: 2 / span 3;
  grid-row: 10 / 11
}

.destination-item-tab-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-evenly;
}

